<template>
    <admin-dashboard-layout>
        <v-row v-if="nftoken">
            <v-col cols="4">
                <CustomFileUpload label="Image" v-model="nftoken.images[0]" />
            </v-col>
            <v-col cols="4">
                <CustomInput
                    label="Title"
                    v-model="nftoken.title"
                    :error="errors.title"
                    @change="errors.title = ''"
                />
                <CustomInput
                    label="Description"
                    type="textarea"
                    v-model="nftoken.description"
                    :error="errors.description"
                    @change="errors.description = ''"
                />
                <v-row>
                    <v-col>
                        <CustomInput
                            label="Website*"
                            v-model="nftoken.website"
                            :error="errors.website"
                            @change="errors.website = ''"
                        />
                    </v-col>
                    <v-col>
                        <CustomInput
                            label="Twitter"
                            v-model="nftoken.twitter"
                            :error="errors.twitter"
                            @change="errors.twitter = ''"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <CustomInput
                            label="Discord"
                            v-model="nftoken.discord"
                            :error="errors.discord"
                            @change="errors.discord = ''"
                        />
                    </v-col>
                    <v-col>
                        <CustomInput
                            label="Opensea"
                            v-model="nftoken.opensea"
                            :error="errors.opensea"
                            @change="errors.opensea = ''"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <span class="font-weight-bold dark--text">
                            Status
                        </span>
                        <v-select
                            dense
                            v-model="nftoken.status"
                            :items="statuses"
                            item-text="title"
                            item-value="value"
                            outlined
                            color="black"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-btn dark @click="handleSubmit">Save</v-btn>
                </v-row>
            </v-col>
        </v-row>
    </admin-dashboard-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "Giveaway",
    components: {
        AdminDashboardLayout: () => import("@/layouts/AdminDashboardLayout"),
    },

    computed: {
        ...mapGetters({
            nftoken: "nftoken/item",
            errors: "nftoken/errors",
        }),
    },
    methods: {
        ...mapMutations({
            setEmptyToken: "nftoken/setItem",
        }),
        ...mapActions({
            setDefaultToken: "nftoken/setDefaultItem",
            updateNftoken: "nftoken/updateItem",
            setNftoken: "nftoken/setItem",
        }),
        handleSubmit() {
            this.updateNftoken();
        },
    },
    created() {
        this.setEmptyToken(null);
        this.setNftoken(1);

        this.statuses = [
            { title: "Submitted", value: "submitted" },
            { title: "Validated", value: "is_valid" },
            { title: "Activated", value: "is_active" },
        ];
    },
};
</script>

<style scoped>
</style>
